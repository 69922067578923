import styled from "@emotion/styled";

export const BookGridContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

export const BookGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 2rem;
`;

export const BookCard = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px) rotateZ(2deg);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    pointer-events: none;
  }
`;

export const BookCover = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
`;

export const BookInfo = styled.div`
  padding: 1rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
`;

export const BookTitle = styled.h3`
  font-size: 1.1rem;
  margin: 0 0 0.5rem;
  color: #5e3023;
`;

export const BookAuthor = styled.p`
  font-size: 0.9rem;
  color: #3a2f2d;
  margin: 0 0 0.5rem;
  font-style: italic;
`;

export const BookRating = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.9rem;
  color: #3a2f2d;
  margin-bottom: 0.5rem;
`;

export const PrettyButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  white-space: nowrap;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background: linear-gradient(270deg, #f28500, #ffcc70, #f28500);
  background-size: 200% 200%; // Enlarging the background size for the gradient animation
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease-in-out;
  width: 120px;
  flex-shrink: 0;

  &:hover {
    transform: scale(1.05);
  }

  // Animation details
  animation: gradientShift 4s ease infinite;

  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const PrettyButtonStatic = styled.button`
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background: black;
  background-size: 200% 200%; // Enlarging the background size for the gradient animation
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    transform: scale(1.01);
  }
`;

export const Footer = styled.footer`
  background-color: #5e3023;
  color: #f8f3e9;
  padding: 3rem 1rem;
`;

export const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

export const FooterSection = styled.div`
  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #f8f3e9;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #f8f3e9;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #e6d8c3;
    }
  }
`;

export const Copyright = styled.div`
  max-width: 1200px;
  margin: 2rem auto 0;
  padding-top: 1rem;
  border-top: 1px solid #8b4513;
  text-align: center;
  color: #f8f3e9;
  font-size: 0.875rem;
`;
