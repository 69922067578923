import React, { useEffect, useState, useRef } from "react";
import { Speaker, Headphones, ArrowRight } from "lucide-react";

import styled from "@emotion/styled";
export const AudioDeviceSelector = ({
  setIsDropdownOpen,

  isDropdownOpen,
  selectedAudioDevice,
  audioDevices,
  setSelectedAudioDevice,
  audioElementRef,
}) => {
  return (
    <SelectWrapper className="audio-device-selector">
      <SelectButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {selectedAudioDevice ? (
            <>
              <DeviceIcon>
                {selectedAudioDevice.label
                  .toLowerCase()
                  .includes("bluetooth") ||
                selectedAudioDevice.label.toLowerCase().includes("airpods") ||
                selectedAudioDevice.label.toLowerCase().includes("wireless") ? (
                  <Headphones size={16} />
                ) : (
                  <Speaker size={16} />
                )}
              </DeviceIcon>
              <span>{selectedAudioDevice.label || "Default Device"}</span>
            </>
          ) : (
            "Select audio output"
          )}
        </div>
        <ArrowRight
          size={16}
          style={{
            transform: isDropdownOpen ? "rotate(90deg)" : "none",
            transition: "transform 0.2s",
          }}
        />
      </SelectButton>

      {isDropdownOpen && (
        <DropdownList>
          {audioDevices.map((device) => (
            <DropdownItem
              key={device.deviceId}
              onClick={async () => {
                setSelectedAudioDevice(device);

                setIsDropdownOpen(false);

                if (audioElementRef.current?.setSinkId) {
                  try {
                    await audioElementRef.current.setSinkId(device.deviceId);
                    console.log("[AudioDevices] Updated output device");
                  } catch (error) {
                    console.warn(
                      "[AudioDevices] Could not set audio output:",
                      error
                    );
                  }
                }
              }}
            >
              <DeviceIcon>
                {device.label.toLowerCase().includes("bluetooth") ||
                device.label.toLowerCase().includes("airpods") ||
                device.label.toLowerCase().includes("wireless") ? (
                  <Headphones size={16} />
                ) : (
                  <Speaker size={16} />
                )}
              </DeviceIcon>
              <span>{device.label || "Default Device"}</span>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </SelectWrapper>
  );
};

const AudioDeviceWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 300px;
`;

// Add this to your existing styled components
const SelectWrapper = styled.div`
  position: relative; /* Make this the reference for absolute positioning */
  margin-bottom: 1rem;
  width: 100%;
  max-width: 300px;
`;
const SelectButton = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  background: white;
  border: 2px solid #8b4513;
  border-radius: 0.5rem;
  color: #4a3728;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;

  &:hover {
    background: #f8f3e9;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(139, 69, 19, 0.2);
  }
`;

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 2px solid #8b4513;
  border-radius: 0.5rem;
  margin-top: 0.25rem;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4a3728;
  transition: all 0.2s;

  &:hover {
    background: #f8f3e9;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const DeviceIcon = styled.span`
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
`;
