import ReactMarkdown from "react-markdown";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";

import LoginModal from "../LoginModal";
import { SidePanel } from "../../components/chat/SidePanel";
import RateLimitModal from "../../components/RateLimit";
import SettingsModal from "../../components/Settings";
import FeedbackModal from "../../components/FeedbackModal";
import {
  Send,
  Image as ImageIcon,
  ArrowRight,
  Loader2,
  PanelLeftOpen,
  CameraIcon,
} from "lucide-react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import InputAdornment from "@mui/material/InputAdornment";
import styled from "@emotion/styled";

import { DEBUG } from "../../functions/debug";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";

export const ChatUI = ({
  messages,
  isLoading,
  inputValue,
  handleSendMessage,
  image,
  setImage,
  setInputValue,
  showLoginNotification,
  sessions,
  setMessages,
  setActiveSessionId,
  setShowSettings,
  setShowFeedback,
  abTests,
  isRateLimited,
  hasSubscription,
  showSettings,
  showFeedback,
  setSessionId,
}) => {
  const navigate = useNavigate();
  const maxLength = 4000;
  const [dragging, setDragging] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const isMobile = window.innerWidth < 768;
  const [isPanelOpen, setIsPanelOpen] = useState(
    !(isMobile || !isAuthenticated)
  );

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const handleInputChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setInputValue(event.target.value);
    }
  };

  const bottomChatRef = useRef(null);
  useEffect(() => {
    bottomChatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const startChatFromKit = (input) => {
    const sanitizedPrompt = input.replace(/\s+/g, "-");
    const encodedPrompt = encodeURIComponent(sanitizedPrompt);
    navigate(`/starters/${encodedPrompt}`);
  };

  const goToStartersAll = () => {
    navigate("/starters");
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileChange = (event) => {
    console.log("handleFileChange called");
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Ensure setImage is correctly defined
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(blob);
        break;
      }
    }
  };

  const startNewChat = () => {
    console.log("Starting new chat");
    setMessages([]);
    if (isAuthenticated) setSessionId(null);

    if (isMobile) {
      togglePanel();
    }
  };

  return (
    <>
      {isPanelOpen ? (
        <SidePanel
          isOpen={isPanelOpen}
          togglePanel={togglePanel}
          sessions={sessions}
          setMessages={setMessages}
          setSessionId={setActiveSessionId}
          setShowSettings={setShowSettings}
          setShowFeedback={setShowFeedback}
          abTests={abTests}
        />
      ) : (
        <ClosedSidePanel />
      )}
      {isRateLimited && <RateLimitModal hasSubscription={hasSubscription} />}
      {showSettings && (
        <SettingsModal
          hasSubscription={hasSubscription}
          setShowSettings={setShowSettings}
        />
      )}
      {showFeedback && <FeedbackModal setShowFeedback={setShowFeedback} />}
      {showLoginNotification && <LoginModal />}
      <MainContainer isPanelOpen={isPanelOpen} DEBUG={DEBUG}>
        <PanelIconContainer onClick={togglePanel}>
          {!isPanelOpen ? (
            <>
              <IconButton onClick={togglePanel} aria-label="Close panel">
                <PanelLeftOpen size={25} />
              </IconButton>
            </>
          ) : (
            <div></div>
          )}
          {!isAuthenticated && (
            <>
              <LoginButton onClick={() => loginWithRedirect()}>
                Login
                <ArrowRight size={15} />
              </LoginButton>
            </>
          )}
        </PanelIconContainer>

        <AnimatePresence>
          {messages.length === 0 ? (
            <ChatPlaceholderContainer
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <HeaderContent>
                <LargeText>An Assistant for</LargeText>
                <GradientTextHeaderLarge>
                  Home Improvement
                </GradientTextHeaderLarge>
              </HeaderContent>
              <StarterSection>
                <CardContainer>
                  {(isMobile ? starterPacks.slice(0, 3) : starterPacks).map(
                    (pack, index) => (
                      <Card
                        key={index}
                        onClick={() => startChatFromKit(pack.functionArgument)}
                      >
                        <CardIcon>{pack.icon}</CardIcon>
                        <CardContent>
                          <CardTitle>{pack.title}</CardTitle>
                          <ArrowRight size={16} color="#f28500" />
                        </CardContent>
                      </Card>
                    )
                  )}
                </CardContainer>
                <SeeAllButton onClick={goToStartersAll}>
                  See all starters
                </SeeAllButton>
              </StarterSection>
            </ChatPlaceholderContainer>
          ) : (
            <ChatContainer DEBUG={DEBUG}>
              <MessagesContainer DEBUG={DEBUG}>
                {messages.map((message, index) => (
                  <MessageContainer
                    key={index}
                    role={message.role}
                    DEBUG={DEBUG}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <MessageText role={message.role}>
                      {message.role === "user" ? (
                        message.content.map((contentItem, idx) => (
                          <div key={idx}>
                            {contentItem.type === "image_url" && (
                              <UserImage
                                src={contentItem.image_url.url}
                                alt="User uploaded"
                              />
                            )}
                            {contentItem.type === "text" && (
                              <ReactMarkdown>{contentItem.text}</ReactMarkdown>
                            )}
                          </div>
                        ))
                      ) : (
                        <ReactMarkdown>{message.content}</ReactMarkdown>
                      )}
                    </MessageText>
                  </MessageContainer>
                ))}
                {isLoading && <LoadingAnimation />}
                <div ref={bottomChatRef} />
              </MessagesContainer>
            </ChatContainer>
          )}
        </AnimatePresence>
        <BottomContainer DEBUG={DEBUG}>
          <InputContainer
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onPaste={handlePaste}
            dragging={dragging}
          >
            <StyledTextField
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type your message here"
              multiline
              maxRows={20}
              inputProps={{
                maxLength: 5000,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton component="label">
                      {/* Camera Button for mobile */}
                      {isMobile && (
                        <>
                          <CameraIcon />
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            capture="environment" // Opens the rear camera directly
                            onChange={handleFileChange}
                          />
                        </>
                      )}
                    </IconButton>
                    <IconButton component="label">
                      <ImageIcon />
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={(e) => {
                          console.log("Input changed");
                          handleFileChange(e);
                        }}
                      />
                    </IconButton>
                    <IconButton onClick={() => handleSendMessage()} edge="end">
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter" && event.metaKey) {
                  handleSendMessage();
                  event.preventDefault();
                } else if (event.key === "k" && event.metaKey) {
                  startNewChat();
                  event.preventDefault();
                }
              }}
            />
            {image && <PreviewImage src={image} alt="Preview" />}
          </InputContainer>
        </BottomContainer>
      </MainContainer>
    </>
  );
};

const starterPacks = [
  {
    title: "placeholder",
    icon: "📕",
    functionArgument: "placeholder",
  },
];
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const MainContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background-color: #f8f9fa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;

  @media (max-width: 768px) {
    display: ${(props) => (props.isPanelOpen ? "none" : "flex")};
    transition: display 0.3s ease;
  }
`;

const PanelIconContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0; // adjust as needed
  width: 100%;
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #333;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #007bff;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 100;
  border-top: 1px solid #e9ecef;
`;

const InputContainer = styled.div`
  width: 100%;
  max-width: 800px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => (props.dragging ? "#f8f9fa" : "transparent")};
  border: ${(props) => (props.dragging ? "2px dashed #007bff" : "none")};
  border-radius: 8px;
  transition: all 0.3s ease;
`;

const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    max-width: 700px;

    @media (max-width: 768px) {
      width: calc(100% - 15px);
    }

    .MuiOutlinedInput-root {
      background-color: #ffffff;
      border-radius: 8px;
      transition: all 0.2s;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      }

      &.Mui-focused {
        box-shadow: 0 0 0 2px #007bff;
      }

      fieldset {
        border-color: #e9ecef;
      }
    }

    .MuiInputBase-input {
      color: #333;
      font-size: 1rem;
    }

    .MuiInputAdornment-root {
      .MuiIconButton-root {
        color: #6c757d;
        transition: color 0.2s;

        &:hover {
          color: #007bff;
        }
      }
    }
  }
`;

const PreviewImage = styled.img`
  width: 100px;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const LoadingAnimation = () => (
  <motion.div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
    }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, rotate: 360 }}
    transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
  >
    <Loader2 size={32} className="text-blue-500" />
  </motion.div>
);
const StarterSection = styled.section`
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  animation: ${fadeIn} 1.2s ease-out;

  @media (max-width: 768px) {
    width: calc(100% - 60px);
    padding: 1rem;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const Card = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(242, 133, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: calc(100% - 50px);
    padding: 1rem;
  }
`;
const CardIcon = styled.span`
  font-size: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const CardTitle = styled.h3`
  font-size: 1.1rem;
  text-align: left;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
const SeeAllButton = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #f28500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(242, 133, 0, 0.2);

  &:hover {
    background-color: #e07a00;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(242, 133, 0, 0.3);
  }
`;

const ChatContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-top: 40px;
`;

const ChatPlaceholderContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }
`;

const MessagesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;

  @media (max-width: 768px) {
    padding: 10px;
    margin-bottom: 60px;
  }
`;

const MessageContainer = styled(motion.div)`
  display: flex;
  justify-content: ${(props) =>
    props.role === "user" ? "flex-end" : "flex-start"};
  padding: 8px;
  margin: 4px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

const MessageText = styled.div`
  background-color: ${(props) =>
    props.role === "user" ? "#e9ecef" : "#f8f9fa"};
  color: #333;
  padding: 12px;
  border-radius: 8px;
  max-width: 80%;
  box-shadow: ${(props) =>
    props.role === "user" ? "0 2px 4px rgba(0, 0, 0, 0.05)" : "none"};

  font-size: 0.9rem;
  line-height: 1.5;
  text-align: left;

  p {
    margin: 0 0 10px 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-left: 30px;
  }
`;

const UserImage = styled.img`
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const ClosedSidePanel = styled.div`
  width: 0px;
  background-color: #f2f2f2;
  transition: width 0.3s ease; // Smooth transition for width
`;

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 2rem;
  background-color: #0070f3;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background-color: #0060df;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
  @media (max-width: 768px) {
    margin-right: 2rem;
    padding: 0.25rem 1rem;
    gap: 0.25rem;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;
const LargeText = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  animation: ${fadeIn} 0.8s ease-out;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
const GradientTextHeaderLarge = styled.h1`
  font-size: 4rem;
  font-weight: 800;
  background: linear-gradient(45deg, #f28500, #ffcc70, #f28500);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 1rem;
  animation: ${gradientAnimation} 5s ease infinite, ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;
