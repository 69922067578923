// App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";
import "./App.css";
import { LandingPage } from "./pages/LandingPage";
import { css, Global } from "@emotion/react";

import { GeneralProvider } from "./contexts/GeneralContext";
import { useEffect } from "react";
import Book from "./static/Book";
import { HelmetProvider } from "react-helmet-async";

import Chat from "./pages/Chat";
import { Library } from "./components/Library";
import { PricingPage } from "./components/PricingPage";
import { Layout } from "./components/Layout";

const DEBUG = false;
function App() {
  return (
    <StyledApp className="App">
      <GeneralProvider>
        <HelmetProvider>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <LandingPage />
                </Layout>
              }
            />
            <Route
              path="/library"
              element={
                <Layout>
                  <Library />
                </Layout>
              }
            />
            <Route
              path="/library/:bookId"
              element={
                <Layout>
                  <Book />
                </Layout>
              }
            />
          </Routes>
        </HelmetProvider>
      </GeneralProvider>
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
