import { Mic, MicOff, X } from "lucide-react";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

export const AudiobookPlayer = ({
  isStreaming,
  toggleMute,
  isMuted,
  onExit,
  isLoading,
}) => {
  const isMicActive = !isMuted && !isStreaming && !isLoading;

  return (
    <AudioControlsContainer>
      <ExitButton onClick={onExit}>
        <X size={24} />
      </ExitButton>

      <AudioStreamingAnimation
        isStreaming={isStreaming}
        isMicActive={isMicActive}
        isLoading={isLoading}
      >
        {isLoading ? (
          <BouncingDots>
            <Dot />
            <Dot />
            <Dot />
          </BouncingDots>
        ) : (
          [...Array(12)].map((_, i) => (
            <Bar
              key={i}
              index={i}
              isStreaming={isStreaming}
              isMicActive={isMicActive}
            />
          ))
        )}
      </AudioStreamingAnimation>

      <VoiceRecordingButton
        onClick={toggleMute}
        isMuted={isMuted}
        isMicActive={isMicActive}
      >
        {isMuted ? <MicOff size={24} /> : <Mic size={24} />}
      </VoiceRecordingButton>
    </AudioControlsContainer>
  );
};

// Enhanced Animations
const waveAnimation = keyframes`
  0% { transform: scaleY(0.3); }
  50% { transform: scaleY(1); }
  100% { transform: scaleY(0.3); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
`;

const bounceAnimation = keyframes`
  0%, 80%, 100% { 
    transform: scale(0.3);
    opacity: 0.6;
  }
  40% { 
    transform: scale(1);
    opacity: 1;
  }
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(220, 38, 38, 0.4); }
  50% { box-shadow: 0 0 15px rgba(220, 38, 38, 0.6); }
  100% { box-shadow: 0 0 5px rgba(220, 38, 38, 0.4); }
`;

// Color constants
const colors = {
  primary: "#8b4513", // Saddle Brown
  secondary: "#d2691e", // Chocolate
  recording: "#dc2626", // Red for recording
  muted: "#74574f", // Darker brown for muted state
  hover: {
    primary: "#a0522d", // Sienna
    recording: "#b91c1c",
    muted: "#5c443c",
  },
};

// Styled Components
const AudioControlsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  padding: 15px;
  background: rgba(139, 69, 19, 0.1);
  border-radius: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
`;

const ExitButton = styled.button`
  background: none;
  border: none;
  color: ${colors.primary};
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);
    color: ${colors.hover.primary};
  }

  &:active {
    transform: scale(0.9);
  }
`;

const AudioStreamingAnimation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100px;
  margin-right: 1.5rem;
  background: ${(props) => {
    if (props.isLoading || props.isStreaming) return `rgba(139, 69, 19, 0.1)`;
    if (props.isMicActive) return `rgba(220, 38, 38, 0.1)`;
    return `rgba(116, 87, 79, 0.1)`;
  }};
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  ${(props) =>
    props.isMicActive &&
    css`
      animation: ${pulseAnimation} 1.5s ease-in-out infinite;
    `}
`;

const Bar = styled.div`
  width: 3px;
  height: ${(props) => `${30 + Math.random() * 40}%`};
  background: ${(props) => {
    if (props.isStreaming)
      return `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`;
    if (props.isMicActive)
      return `linear-gradient(to bottom, ${colors.recording}, ${colors.hover.recording})`;
    return `linear-gradient(to bottom, ${colors.muted}, ${colors.hover.muted})`;
  }};
  margin: 0 2px;
  border-radius: 3px;
  transform-origin: bottom;
  animation: ${(props) =>
    props.isStreaming
      ? css`
          ${waveAnimation} 1.2s ease-in-out infinite
        `
      : "none"};
  animation-delay: ${(props) => props.index * 0.1}s;
`;

const BouncingDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: linear-gradient(45deg, ${colors.primary}, ${colors.secondary});
  border-radius: 50%;
  animation: ${bounceAnimation} 1.5s infinite ease-in-out;

  &:nth-of-type(1) {
    animation-delay: 0s;
  }
  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;

const VoiceRecordingButton = styled.button`
  background: ${(props) => {
    if (props.isMuted) return colors.muted;
    return props.isMicActive
      ? colors.recording
      : `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`;
  }};
  color: #f0e6d2;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-size: 200% 200%;

  ${(props) =>
    props.isMicActive &&
    css`
      animation: ${glowAnimation} 2s ease-in-out infinite;
    `}

  &:hover {
    background-color: ${(props) => {
      if (props.isMuted) return colors.hover.muted;
      return props.isMicActive ? colors.hover.recording : colors.hover.primary;
    }};
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
