import React, { useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Info, BookOpen, Check } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
import { createCheckOutSesssion } from "../functions/apiFunctions";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(74, 55, 40, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #f0e6d2;
  padding: 3rem;
  border-radius: 2rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 90%;
  text-align: center;
  animation: ${fadeIn} 0.5s ease-out;
`;

const ModalTitle = styled.h2`
  font-size: 2.5rem;
  color: #4a3728;
  margin-bottom: 1.5rem;
  font-weight: 800;
`;

const ModalText = styled.p`
  font-size: 1.2rem;
  color: #6b5b4f;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const DisclaimerBox = styled.div`
  background-color: #e6d8c3;
  border: 2px solid #d2b48c;
  border-radius: 1.5rem;
  padding: 1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: flex-start;
  text-align: left;
`;

const DisclaimerIcon = styled(Info)`
  color: #8b4513;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const DisclaimerText = styled.p`
  font-size: 0.8rem;
  color: #4a3728;
  margin: 0;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const PreorderButton = styled(Button)`
  background-color: #8b4513;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #d2691e;
  }
`;

const CloseButton = styled(Button)`
  background-color: transparent;
  color: #8b4513;
  border: 2px solid #8b4513;

  &:hover {
    background-color: #f0e6d2;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2e7d32;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1.5rem;
`;

const BookIcon = styled(BookOpen)`
  margin-right: 0.5rem;
`;

const CheckIcon = styled(Check)`
  margin-right: 0.5rem;
`;

export default function PreorderModal({
  isOpen,
  onClose,
  bookTitle,
  fromLibrary,
  rateLimit,
}) {
  const [isPreordered, setIsPreordered] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  if (!isOpen) return null;

  const handlePreorder = async () => {
    // Here you would typically handle the preorder logic
    // setIsPreordered(true);

    const token = await getAccessTokenSilently();
    const response = await createCheckOutSesssion(token);
    // Handle the response here
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalTitle>Preorder Now!</ModalTitle>
        {fromLibrary ? (
          <ModalText>
            The expected launch date is January 2025. Preorder and gain access
            to 10 hours of listening to any book in our library.
          </ModalText>
        ) : rateLimit ? (
          <ModalText>
            You have exceeded your free sample limit. Please preorder to get
            access when the full product is available.
          </ModalText>
        ) : (
          <ModalText>
            Get access to 10 hours of listening of any book in our library.
            Expected availability: January 2025
          </ModalText>
        )}

        <DisclaimerBox>
          <DisclaimerText>
            Please note: This project is experimental and part of our research
            into AI-powered audiobooks. While we strive for excellence, the
            product may differ from expectations.
          </DisclaimerText>
        </DisclaimerBox>
        {isPreordered ? (
          <SuccessMessage>
            <CheckIcon size={24} />
            Preorder Successful! Thank you for your support.
          </SuccessMessage>
        ) : (
          <ButtonGroup>
            <PreorderButton onClick={handlePreorder}>
              <BookIcon size={20} />
              Preorder Now
            </PreorderButton>
            <CloseButton onClick={onClose}>Maybe Later</CloseButton>
          </ButtonGroup>
        )}
      </ModalContent>
    </ModalOverlay>
  );
}
