import React, { useState, useEffect } from "react";
import {
  ArrowRight,
  BookText,
  Search,
  Star,
  House,
  Plus,
  Sparkles,
} from "lucide-react";
import { css, Global, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { books } from "../static/books";
import {
  BookAuthor,
  BookCard,
  BookCover,
  BookGrid,
  BookGridContainer,
  BookInfo,
  BookRating,
  BookTitle,
  Copyright,
  Footer,
  FooterContent,
  FooterSection,
} from "../styles/styledComponents";
import { useNavigate } from "react-router-dom";
import PreorderModal from "./PurchaseModal";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";

const genres = ["All", ...new Set(books.map((book) => book.genre))];

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const LibraryContainer = styled.div`
  width: 100%;

  padding: 4rem 0 0 0;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoAnimated = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: ${floatAnimation} 3s ease-in-out infinite;
`;

const LogoTextAnimated = styled.span`
  font-family: "Playfair Display", serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #5e3023;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const HeroSection = styled.section`
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    background: url("/placeholder.svg?height=200&width=200") no-repeat center
      center;
    background-size: contain;
    opacity: 0.1;
    z-index: -1;
  }

  &::before {
    top: -50px;
    left: -50px;
    transform: rotate(-15deg);
  }

  &::after {
    bottom: -50px;
    right: -50px;
    transform: rotate(15deg);
  }
`;

const Heading = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Subheading = styled.p`
  font-size: 1.2rem;
  color: #5e3023;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, #5e3023, #8b4513, #d2691e);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
  -webkit-background-clip: text;
  color: transparent;
`;

const SearchAndFilterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  transition: box-shadow 0.3s ease;

  &:focus-within {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const SearchInput = styled.input`
  border: none;
  background: transparent;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: #3a2f2d;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const GenreFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
`;

const GenreButton = styled.button`
  background-color: ${(props) => (props.active ? "#5e3023" : "#e6d8c3")};
  color: ${(props) => (props.active ? "#f8f3e9" : "#5e3023")};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${(props) => (props.active ? "#8b4513" : "#d2b48c")};
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`;

const ExploreButton = styled.button`
  background-color: #5e3023;
  color: #f8f3e9;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #8b4513;
    transform: translateX(5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`;

const KidsBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(45deg, #8a2be2, #ff69b4);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  transform: rotate(6deg);
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const FloatingBubble = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  opacity: 0.7;
  animation: ${floatAnimation} 2s ease-in-out infinite;
  animation-delay: ${(props) => props.delay || "0s"};
`;

const KidsBookCover = styled(BookCover)`
  border: 3px dashed #9c27b0;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    border-color: #ff69b4;
  }
`;

export const Library = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [filteredBooks, setFilteredBooks] = useState(books);

  useEffect(() => {
    const filtered = books.filter(
      (book) =>
        (selectedGenre === "All" || book.genre === selectedGenre) &&
        (book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          book.author.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredBooks(filtered);
  }, [searchTerm, selectedGenre]);

  const goToBook = (id) => {
    console.log(`Navigating to /library/${id}`);
    navigate(`/library/${id}`);
  };

  const handleCreateClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>
          Explore the Library | Speakwithbooks.com - Interactive Book Discovery
        </title>
        <meta
          name="description"
          content="Discover and explore a wide range of books with Speakwithbooks.com. Use voice-based interactions to find your next read and delve into an enchanted library of possibilities."
        />
        <link rel="canonical" href={`https://www.speakwithbooks.com/library`} />
      </Helmet>
      <LibraryContainer>
        <Header>
          <LogoAnimated>
            <div>
              <BookText size={32} color="#5e3023" />
              <LogoTextAnimated>Enchanted Library</LogoTextAnimated>
            </div>
          </LogoAnimated>
        </Header>

        <HeroSection>
          <Heading>
            Experience your first <GradientText>AI Audiobook</GradientText>
          </Heading>
          <Subheading>
            Enjoy learning through engaging audio experiences.
          </Subheading>
        </HeroSection>

        <SearchAndFilterSection>
          <SearchBar>
            <Search size={20} color="#5e3023" />
            <SearchInput
              type="text"
              placeholder="Search for your next magical read..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBar>
          <GenreFilter>
            {genres.map((genre) => (
              <GenreButton
                key={genre}
                active={selectedGenre === genre}
                onClick={() => setSelectedGenre(genre)}
              >
                {genre}
              </GenreButton>
            ))}
          </GenreFilter>
        </SearchAndFilterSection>
        <BookGridContainer>
          <BookGrid>
            <CreateBookCard onClick={handleCreateClick}>
              <CreateBookContent>
                <CreateBookIcon>
                  <Plus size={32} />
                </CreateBookIcon>
                <CreateBookTitle>Create Your Own AI Audiobook</CreateBookTitle>
                <CreateBookDescription>
                  Transform your stories into immersive audio experiences
                </CreateBookDescription>
                <CreateButton>
                  Start Creating <Sparkles size={16} />
                </CreateButton>
              </CreateBookContent>
            </CreateBookCard>
            {filteredBooks.map((book) => (
              <BookCard key={book.id} onClick={() => goToBook(book.id)}>
                <div style={{ position: "relative" }}>
                  {book.forKids && (
                    <>
                      <KidsBadge>For Kids!</KidsBadge>
                      <FloatingBubble
                        color="#FFD700"
                        style={{ top: "-10px", right: "30px" }}
                      />
                      <FloatingBubble
                        color="#FF69B4"
                        style={{ top: "-15px", right: "50px" }}
                        delay="0.5s"
                      />
                    </>
                  )}
                  {book.forKids ? (
                    <KidsBookCover
                      src={book.cover}
                      alt={`Cover of ${book.title}`}
                    />
                  ) : (
                    <BookCover
                      src={book.cover}
                      alt={`Cover of ${book.title}`}
                    />
                  )}
                </div>
                <BookInfo>
                  <BookTitle>{book.title}</BookTitle>
                  <BookAuthor>by {book.author}</BookAuthor>
                  <BookRating>
                    <Star size={16} color="#FFD700" fill="#FFD700" />
                    Not yet reviewed
                  </BookRating>
                  <ExploreButton>
                    Begin Journey
                    <ArrowRight size={16} />
                  </ExploreButton>
                </BookInfo>
              </BookCard>
            ))}
          </BookGrid>
        </BookGridContainer>
        <PreorderModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          bookTitle="AI Audiobook Creator"
          fromLibrary={true}
        />
        <Footer style={{ marginTop: "100px" }}>
          <FooterContent>
            <FooterSection>
              <h3>SpeakWithBooks</h3>
              <p>Explore a world of AI audiobooks</p>
            </FooterSection>
            <FooterSection>
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                {isAuthenticated ? (
                  <li>
                    <a href="#" onClick={() => logout()}>
                      Logout
                    </a>
                  </li>
                ) : (
                  <li>
                    <a href="#" onClick={() => loginWithRedirect()}>
                      Login
                    </a>
                  </li>
                )}
              </ul>
            </FooterSection>
          </FooterContent>
          <Copyright>
            <p>
              &copy; {new Date().getFullYear()} SpeakWithBooks. All rights
              reserved.
            </p>
          </Copyright>
        </Footer>
      </LibraryContainer>
    </>
  );
};

const CreateBookCard = styled(BookCard)`
  background: linear-gradient(135deg, #5e3023 0%, #8b4513 100%);
  border: 2px dashed #f8f3e9;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    border: 2px dashed #ffd700;
    box-shadow: 0 8px 16px rgba(94, 48, 35, 0.2);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      transparent 0%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 100%
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }

  &:hover::before {
    transform: translateX(100%);
  }
`;

const CreateBookContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: #f8f3e9;
  height: 100%;
  gap: 1rem;
`;

const CreateBookIcon = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreateBookTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const CreateBookDescription = styled.p`
  font-size: 0.9rem;
  opacity: 0.9;
  margin: 0;
`;

const CreateButton = styled(ExploreButton)`
  background-color: #ffd700;
  color: #5e3023;
  font-weight: bold;

  &:hover {
    background-color: #ffed4a;
  }
`;
