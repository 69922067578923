import { useNavigate } from "react-router-dom";
import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";
import { BookOpen } from "lucide-react";

export const Layout = ({ children }) => {
  const navigate = useNavigate();

  const goToLibrary = () => {
    navigate("/library");
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lora:wght@400;700&display=swap");

          * {
            box-sizing: border-box;
          }

          body {
            font-family: "Lora", serif;
            background-color: #f8f3e9;
            color: #3a2f2d;
            margin: 0;
            padding: 0;
            overflow-x: hidden; /* Prevent horizontal scroll */
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: "Playfair Display", serif;
          }
        `}
      />
      <Container>
        <Navigation>
          <Logo onClick={goHome}>
            <BookOpen size={32} color="#5e3023" />
            <LogoText>SpeakWithBooks</LogoText>
          </Logo>
          <Nav>
            <NavItem onClick={goToLibrary}>Library</NavItem>
          </Nav>
        </Navigation>
        <Main>{children}</Main> {/* Render the routed component here */}
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll in the container */
`;

const Main = styled.main`
  max-width: 100%; /* Ensure main content stays within the viewport */
  padding: 0; /* Adjust padding if necessary */
`;

const Navigation = styled.header`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const LogoText = styled.span`
  font-family: "Playfair Display", serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5e3023;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const NavItem = styled.a`
  color: #4a3728;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #8b4513;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: #8b4513;
    &:after {
      visibility: visible;
      transform: scaleX(1);
    }
  }
`;
