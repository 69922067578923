import dinosaursCover from "../images/books/dinosaurs.jpeg";
// import egyptCover from "../images/books/egypt.jpeg";
import airplanesCover from "../images/books/airplanes.jpg";
import volcanoCover from "../images/books/volcano.jpeg";
import gardenCover from "../images/books/garden.jpeg";
import LibrarianCover from "../images/books/librarian.png";

export const books = [
  {
    id: 1,
    title: "Dinosaurs: Chronicles of the Forgotten Giants",
    author: "SpeakWithBooks",
    genre: "Science",
    rating: 3.0,
    forKids: true,
    cover: dinosaursCover,
    description:
      "Explore the fascinating world of dinosaurs and their reign on Earth.",
    features: [
      {
        icon: "🦖",
        title: "Jurassic Era",
        description: "Learn about the different periods of the dinosaur age.",
      },
      {
        icon: "🌍",
        title: "Global Impact",
        description: "Understand how dinosaurs shaped the Earth's history.",
      },
      {
        icon: "🦕",
        title: "Diverse Species",
        description: "Discover the variety of dinosaur species that existed.",
      },
    ],
  },
  {
    id: 2,
    title: "Airplanes: How They Soar",
    author: "SpeakWithBooks",
    genre: "Technology",
    rating: 3.0,
    cover: airplanesCover,
    description:
      "Discover the technology and engineering behind the marvel of flight.",
    features: [
      {
        icon: "✈️",
        title: "Flight Mechanics",
        description: "Understand the principles that allow planes to fly.",
      },
      {
        icon: "🛠️",
        title: "Engineering Marvels",
        description: "Learn about the engineering breakthroughs in aviation.",
      },
      {
        icon: "🌐",
        title: "Global Connectivity",
        description: "See how airplanes connect the world.",
      },
    ],
  },
  {
    id: 3,
    title: "The Volcano: Secrets of the Lava Flow",
    author: "SpeakWithBooks",
    genre: "Geology",
    rating: 3.0,
    cover: volcanoCover,
    description:
      "Uncover the secrets of volcanoes and the powerful forces of nature.",
    features: [
      {
        icon: "🌋",
        title: "Volcanic Eruptions",
        description: "Witness the power and beauty of volcanic eruptions.",
      },
      {
        icon: "🧭",
        title: "Geological Impact",
        description: "Learn how volcanoes shape the Earth's landscape.",
      },
      {
        icon: "🔥",
        title: "Lava Flow",
        description: "Explore the movement and effects of lava flows.",
      },
    ],
  },
  {
    id: 4,
    title: "A Local Guide to Gardening",
    author: "SpeakWithBooks",
    genre: "Gardening",
    rating: 3.0,
    cover: gardenCover, // Assuming no cover image is provided
    description:
      "A comprehensive guide to starting and maintaining your own garden.",
    features: [
      {
        icon: "🌱",
        title: "Plant Selection",
        description: "Choose the right plants for your local climate.",
      },
      {
        icon: "🌿",
        title: "Sustainable Practices",
        description: "Learn eco-friendly gardening techniques.",
      },
      {
        icon: "🌻",
        title: "Seasonal Tips",
        description: "Get tips for gardening throughout the year.",
      },
    ],
  },
  {
    id: 5,
    title: "AI Librarian: An expert on all things books",
    author: "SpeakWithBooks",
    genre: "Books",
    rating: 3.0,
    cover: LibrarianCover, // Assuming no cover image is provided
    description:
      "Engage in insightful discussions with an AI Librarian about any book. Ask the librarian questions about a character’s background and relationships. Explore themes and plots.",
    features: [
      {
        icon: "🤖",
        title: "AI-Powered Discussions",
        description: "Interact with an AI to explore book themes and insights.",
      },
      {
        icon: "📚",
        title: "Vast Book Knowledge",
        description: "Access a wide range of books for AI discussions.",
      },
      {
        icon: "💡",
        title: "Insightful Analysis",
        description:
          "Gain new perspectives and understanding of your favorite books.",
      },
    ],
  },
];
