import React, { useEffect, useState, useRef } from "react";
import Hls from "hls.js";

import { useParams } from "react-router-dom";
import { ArrowRight, Star, Mic, MicOff } from "lucide-react";
import styled from "@emotion/styled";
import { css, Global, keyframes } from "@emotion/react";
import { books } from "./books";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import KidsBookUI from "../components/KindsBookUI";
import PurchaseModal from "../components/PurchaseModal";
import { runLocal, testBook } from "../functions/debug";
import { AudioDeviceSelector } from "../components/AudioDeviceSelector";
import { AudiobookPlayer } from "../components/AudiobookPlayer";

const baseUrl = runLocal
  ? "http://127.0.0.1:5000"
  : "https://main-backend-api-332ea47a673b.herokuapp.com";

const Book = () => {
  // Existing setup and state management
  const { logout, getAccessTokenSilently } = useAuth0();
  const { bookId } = useParams();
  const [bookData, setBookData] = useState(null);

  const [isMuted, setIsMuted] = useState(false);
  const isMutedRef = useRef(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    // Here you would also implement the actual muting/unmuting of the audio
  };

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const [isStreaming, setIsStreaming] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const isListeningRef = useRef(false);
  const [started, setStarted] = useState(false); // Add loading state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [audioConstraints, setAudioConstraints] = useState({ audio: true });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rateLimit, setRateLimit] = useState(false);

  const [base64Audio, setBase64Audio] = useState(null); // Add this state
  const audioChunksRef = useRef([]); // Define `audioChunks` as a ref

  const recorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const animationFrameRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const [isSafari, setIsSafari] = useState(false);
  // const analyserRef = useRef(null);
  // const [audioLevels, setAudioLevels] = useState(Array(12).fill(0));
  const [isLoading, setIsLoading] = useState(false);

  // Refs for AudioContext and HLS
  const audioElementRef = useRef(null);

  const hlsRef = useRef(null);

  // Initialize getUserMedia and AudioContext on page load
  const streamRef = useRef(null);

  const setupAudio = async () => {
    console.log("[setupAudio]");
    try {
      // TODO: if this actually words then bring back the device selector
      // Stop any existing stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      const stream = await navigator.mediaDevices.getUserMedia(
        audioConstraints
      );
      const devices = await navigator.mediaDevices.enumerateDevices(); // HOLY GRAIL CODE: never changes this order
      // console.log("[setupAudio] Audio devices:", devices);

      // Filter for only audio output devices
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      const audioOutputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );

      // console.log("[setupAudio] Audio input devices:", audioInputDevices);
      // console.log("[setupAudio] Audio output devices:", audioOutputDevices);

      // For Safari/iOS, use input devices instead of output devices
      const deviceList = audioInputDevices;

      if (deviceList.length > 0) {
        setAudioDevices(deviceList);
        // Set default device if none selected
        if (!selectedAudioDevice) {
          // Try to find and select AirPods first if available
          const airpodsDevice = deviceList.find((device) =>
            device.label.toLowerCase().includes("airpods")
          );
          setSelectedAudioDevice(airpodsDevice || deviceList[0]);
        }
      }

      // If we're on Safari/iOS and switching input devices, update the stream
      if (selectedAudioDevice) {
        const newConstraints = {
          audio: {
            deviceId: { exact: selectedAudioDevice.deviceId },
          },
        };
        console.log("[setupAudio] setAudioConstraints(newConstraints)");
        setAudioConstraints(newConstraints);
      }

      streamRef.current = stream;
      audioContextRef.current = new AudioContext();
      const source = audioContextRef.current.createMediaStreamSource(stream);

      console.log("[setupAudio] Audio initialized successfully! ");
      setStarted(true); // Set loading state to false

      // Tie audio to user gesture by immediately starting the context
      if (audioContextRef.current.state === "suspended") {
        await audioContextRef.current.resume();
      }
    } catch (error) {
      console.error("Error initializing audio:", error);
    }
  };

  // Add effect to handle device changes
  useEffect(() => {
    const handleDeviceChange = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      const audioOutputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );

      // Use appropriate device list based on platform
      const deviceList = audioInputDevices;

      setAudioDevices(deviceList);
    };

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange
      );
    };
  }, []);

  // Effect to handle audio device changes
  useEffect(() => {
    const setupAudioDevice = async () => {
      console.log("[setupAudioDevice]");
      if (!selectedAudioDevice) return;

      console.log("[setupAudioDevice] ===> Safari detected");
      // For Safari/iOS, we need to recreate the stream with the new input device
      const newConstraints = {
        audio: {
          deviceId: { exact: selectedAudioDevice.deviceId },
        },
      };
      setAudioConstraints(newConstraints);
      await setupAudio(); // Recreate stream with new device
    };

    setupAudioDevice();
  }, [selectedAudioDevice, isSafari]);

  const loadHLSStream = async () => {
    console.log("[loadHLSStream]");
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const payload = {
        bookId,
        testBook,
        sessionId, // Include the sessionId from the state if it exists
      };

      if (base64Audio) {
        payload.base64Audio = base64Audio;
      }

      const startStreamUrl = `${baseUrl}/api/book/start_stream`;
      const response = await fetch(startStreamUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 429) {
        console.warn("[loadHLSStream] Free samples exceeded");
        setIsModalOpen(true); // Open a modal to prompt the user to purchase access
        setRateLimit(true);
        stopStreamingAndReset();
        setIsLoading(false); // Reset loading state
        return;
      }

      const data = await response.json();

      // Update the sessionId state
      if (data.session_id) {
        console.log("[loadHLSStream] Received session_id:", data.session_id);
        setSessionId(data.session_id);
      }

      const hlsUrl = data.playlist_url;

      if (!audioElementRef.current) {
        console.warn(
          "[loadHLSStream] Audio element ref is null, waiting for ref to initialize"
        );
        setIsLoading(false); // Reset loading state
        return;
      }

      console.log("[loadHLSStream] HLS.js is supported");
      // HLS.js is supported
      const hlsConfig = {
        xhrSetup: function (xhr, url) {
          xhr.setRequestHeader("Authorization", `Bearer ${token}`);
          xhr.timeout = 0;
        },
        liveSyncDurationCount: 0,
        enableWorker: true,
        lowLatencyMode: true,
        manifestLoadingMaxRetry: 10,
        manifestLoadingRetryDelay: 2000,
        manifestLoadingMaxRetryTimeout: 64000,
        levelLoadingMaxRetry: 5,
        levelLoadingRetryDelay: 2000,
        levelLoadingMaxRetryTimeout: 32000,
      };

      const hls = new Hls(hlsConfig);
      hlsRef.current = hls;

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        console.log("[loadHLSStream] Media attached");
      });

      hls.attachMedia(audioElementRef.current);
      hls.loadSource(hlsUrl);

      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        console.log("[loadHLSStream] Manifest parsed, starting playback");
        hls.startLoad();
        audioElementRef.current.play();
        setIsLoading(false); // Reset loading state
        console.log("[loadHLSStream] ====> setting is loading to false ");
      });

      hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
        console.log("[loadHLSStream] Level loaded, live:", data.details.live);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error("[loadHLSStream] HLS error:", data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error(
                "[loadHLSStream] Network error in HLS stream, trying to recover..."
              );
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error(
                "[loadHLSStream] Media error in HLS stream, trying to recover..."
              );
              hls.recoverMediaError();
              break;
            default:
              hls.destroy();
              break;
          }
        }
        console.log("[loadHLSStream] ====> setting is loading to false ");
        setIsLoading(false); // Reset loading state
      });

      // setupAudioAnalysis();
    } catch (error) {
      console.error("[loadHLSStream] Error initializing HLS stream:", error);
      setIsLoading(false); // Reset loading state on error
    }
  };

  // useEffect(() => {
  //   return () => {
  //     cancelAnimationFrame(animationFrameRef.current);
  //     if (hlsRef.current) hlsRef.current.destroy();
  //     if (audioContextRef.current) audioContextRef.current.close();
  //   };
  // }, []);

  // const setupAudioAnalysis = () => {
  //   audioContextRef.current = new (window.AudioContext ||
  //     window.webkitAudioContext)();
  //   const source = audioContextRef.current.createMediaElementSource(
  //     audioElementRef.current
  //   );
  //   const analyser = audioContextRef.current.createAnalyser();

  //   analyser.fftSize = 256; // Controls the frequency resolution
  //   analyserRef.current = analyser;
  //   source.connect(analyser);
  //   analyser.connect(audioContextRef.current.destination);

  //   const dataArray = new Uint8Array(analyser.frequencyBinCount);

  //   const animateBars = () => {
  //     analyser.getByteFrequencyData(dataArray);
  //     const barHeights = Array.from(dataArray).slice(0, 12); // First 12 frequencies
  //     setAudioLevels(barHeights);
  //     animationFrameRef.current = requestAnimationFrame(animateBars);
  //   };

  //   animateBars();
  // };

  // Start and stop HLS streaming
  const startStream = async () => {
    console.log("[startStream] Starting HLS stream");
    await setupAudio();

    setIsStreaming(true);
  };

  const stopStreamingAndReset = () => {
    console.log("[onExit] Exiting audiobook player...");

    // Stop the streaming process
    if (isStreaming) {
      setIsStreaming(false);
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    }

    // Reset other states if needed
    setStarted(false);
    setSessionId(null);
    audioContextRef.current?.close();
    streamRef.current?.getTracks().forEach((track) => track.stop());
  };

  // Convert a Blob to a base64 string
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const startListening = async () => {
    console.log("[startListening]");

    // If muted, skip recording and go straight to streaming

    try {
      isListeningRef.current = true;
      console.log("[startListening] Starting audio recording");

      if (audioContextRef.current?.state === "suspended") {
        await audioContextRef.current.resume();
      }

      // Use existing stream
      if (!streamRef.current) {
        console.error("[startListening] No media stream available");
        return;
      }

      const options = {
        mimeType: "audio/webm;codecs=opus",
        audioBitsPerSecond: 128000,
      };

      let mediaRecorder;
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        mediaRecorder = new MediaRecorder(streamRef.current);
      } else {
        mediaRecorder = new MediaRecorder(streamRef.current, options);
      }

      // Clear previous chunks before starting a new recording
      audioChunksRef.current = [];
      mediaRecorder.ondataavailable = (event) =>
        audioChunksRef.current.push(event.data);

      recorderRef.current = mediaRecorder;
      mediaRecorder.start();

      // Set up VAD and ensure it cleans up after stopping
      const cleanupVAD = setupVAD(streamRef.current);

      // Cleanup VAD when recording stops
      recorderRef.current.onstop = () => {
        cleanupVAD();
      };
    } catch (error) {
      console.error("[startListening] Error during recording:", error);
    }
  };

  const stopListening = () => {
    if (recorderRef.current && recorderRef.current.state === "recording") {
      console.log("[stopListening] Stopping recording");

      // Define `onstop` handler just before stopping the recorder
      recorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: recorderRef.current.mimeType,
        });
        const processedBlob = audioBlob;
        const base64Audio = await blobToBase64(processedBlob);

        setBase64Audio(base64Audio); // Save audio data for the next request

        setTimeout(() => startStream(), 100); // Small delay before starting the next stream
      };

      recorderRef.current.stop();
    }

    isListeningRef.current = false;
    console.log("[stopListening] Stopped listening and ready to restart");
  };

  // VAD setup to detect silence and stop listening
  const setupVAD = (stream) => {
    console.log("[setupVAD] Setting up VAD");

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }

    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
      silenceTimeoutRef.current = null;
    }

    const analyser = audioContextRef.current.createAnalyser();
    const microphone = audioContextRef.current.createMediaStreamSource(stream);
    microphone.connect(analyser);

    analyser.fftSize = 2048;
    const dataArray = new Uint8Array(analyser.fftSize);

    const detectSilence = () => {
      if (!isListeningRef.current) {
        console.log("[VAD] Stopping silence detection - no longer listening");
        return;
      }

      analyser.getByteTimeDomainData(dataArray);

      const rms = Math.sqrt(
        dataArray.reduce((sum, value) => sum + (value - 128) ** 2, 0) /
          dataArray.length
      );

      // console.log("[VAD] Current RMS:", rms);

      // Map RMS to audioLevels for visual feedback
      // const scaledLevels = Array(12)
      //   .fill(0)
      //   .map(() => Math.min(Math.max(rms * 10, 20), 100)); // Scale and constrain values
      // setAudioLevels(scaledLevels);

      if (rms < 10) {
        if (!silenceTimeoutRef.current) {
          console.log("[VAD] Silence detected, starting timeout");
          silenceTimeoutRef.current = setTimeout(() => {
            console.log("[VAD] Silence timeout reached, stopping listening");
            if (isListeningRef.current) {
              stopListening(); // Stop listening when silence is detected
            }
          }, 3000);
        }
      } else {
        if (silenceTimeoutRef.current) {
          console.log("[VAD] Sound detected, clearing timeout");
          clearTimeout(silenceTimeoutRef.current);
          silenceTimeoutRef.current = null;
        }
      }

      animationFrameRef.current = requestAnimationFrame(detectSilence);
    };

    detectSilence();

    return () => {
      console.log("[VAD] Cleaning up");
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
      microphone.disconnect();
      analyser.disconnect();
    };
  };

  // Function to set up HLS stream
  useEffect(() => {
    console.log("[useEffect] isStreaming:", isStreaming);

    // Only call loadHLSStream if streaming starts
    if (isStreaming) {
      loadHLSStream();
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy(); // Ensure HLS is cleaned up
        hlsRef.current = null; // Reset reference to prevent memory leaks
      }
    };
  }, [isStreaming]);

  useEffect(() => {
    if (!audioElementRef.current) return;

    const audioElement = audioElementRef.current;

    const handlePlaybackEnded = async () => {
      console.log("[HLS] Media playback ended, starting listening");
      setIsStreaming(false);

      if (isMutedRef.current) {
        console.log(
          "[startListening] Audio is muted, starting stream without recording"
        );
        startStream();
        return;
      }

      startListening();
    };

    audioElement.addEventListener("ended", handlePlaybackEnded);

    return () => {
      audioElement.removeEventListener("ended", handlePlaybackEnded);
    };
  }, [audioElementRef.current]);

  // Load book data
  useEffect(() => {
    const loadBookData = async () => {
      const selectedBook = books.find((book) => book.id === parseInt(bookId));
      setBookData(selectedBook);
    };
    loadBookData();
  }, [bookId]);

  if (!bookData) return <LoadingScreen />;

  // State to track if user has initiated playback

  return (
    <>
      <Container>
        <audio
          ref={audioElementRef}
          controls
          playsInline
          webkit-playsinline=""
          autoPlay
          muted={false}
          style={{ display: "none" }}
        />
        <HeroSection>
          <HeroContent>
            <Heading>
              <GradientText>{bookData.title}</GradientText>
            </Heading>
            <AuthorName>by {bookData.author}</AuthorName>

            {!started ? (
              <>
                <Subheading>{bookData.description}</Subheading>
                {/* <Rating>
                  <Star size={20} color="#FFD700" />
                  Not rated
                </Rating> */}
                <ButtonGroup>
                  <PrimaryButton
                    onClick={startStream}
                    disabled={false}
                    isDisabled={false}
                  >
                    <Mic />
                    <div>Try Sample</div>
                  </PrimaryButton>

                  <SecondaryButton onClick={() => setIsModalOpen(true)}>
                    Preorder AI Audiobook
                  </SecondaryButton>
                </ButtonGroup>
              </>
            ) : (
              <PlayerContainer>
                <AudiobookPlayer
                  isStreaming={isStreaming}
                  toggleMute={toggleMute}
                  isMuted={isMuted}
                  onExit={stopStreamingAndReset}
                  isLoading={isLoading}
                />

                <AudioDeviceSelector
                  isDropdownOpen={isDropdownOpen}
                  setIsDropdownOpen={setIsDropdownOpen}
                  audioDevices={audioDevices}
                  selectedAudioDevice={selectedAudioDevice}
                  setSelectedAudioDevice={setSelectedAudioDevice}
                  audioElementRef={audioElementRef}
                />
              </PlayerContainer>
            )}
          </HeroContent>
          <PurchaseModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            bookTitle={bookData.title}
            fromLibrary={false}
            rateLimit={rateLimit}
          />
          <HeroImageWrapper>
            {bookData.forKids && <KidsBookUI />}
            <HeroImage
              src={bookData.cover}
              alt={`Cover of ${bookData.title}`}
            />
            <ImageOverlay />
          </HeroImageWrapper>
        </HeroSection>

        <FeaturesSection>
          <SectionTitle>Uncover the Magic Within</SectionTitle>
          <FeatureContainer>
            {bookData.features.map((feature, index) => (
              <FeatureCard key={index}>
                <FeatureIcon>{feature.icon}</FeatureIcon>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureCard>
            ))}
          </FeatureContainer>
        </FeaturesSection>

        <CTASection>
          <SectionTitle>Ready to Embark on Your Journey?</SectionTitle>
          <Subheading>
            Immerse yourself in the world of {bookData.title} with our
            AI-powered audiobook experience.
          </Subheading>
          <CTAButton onClick={() => setIsModalOpen(true)}>
            Preorder now <ArrowRight size={20} />
          </CTAButton>
        </CTASection>

        <Footer>
          <FooterContent>
            <FooterSection>
              <h3>SpeakWithBooks</h3>
              <p>Explore a world of AI audiobooks</p>
            </FooterSection>
            <FooterSection>
              <h3>Explore</h3>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/library">Library</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <a
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    Log out
                  </a>
                </li>
              </ul>
            </FooterSection>
          </FooterContent>
          <Copyright>
            <p>
              &copy; {new Date().getFullYear()} SpeakWithBooks. All rights
              reserved.
            </p>
          </Copyright>
        </Footer>
      </Container>
    </>
  );
};

export default withAuthenticationRequired(Book);

const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  color: #4a3728;
  justify-content: center;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const Container = styled.div`
  min-height: 100vh;
  background-color: #f0e6d2;
  overflow-x: hidden;
`;

const HeroSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 1rem;
  display: flex;
  align-items: center;
  gap: 4rem;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeroContent = styled.div`
  flex: 1;
`;

const HeroImageWrapper = styled.div`
  flex: 1;
  position: relative;
  height: 500px;
  animation: ${float} 6s ease-in-out infinite;
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05) rotate(1deg);
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(139, 69, 19, 0.3),
    rgba(74, 55, 40, 0.3)
  );
  border-radius: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, #8b4513, #d2691e);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
`;

const Heading = styled.h1`
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const AuthorName = styled.h2`
  font-size: 1.5rem;
  color: #6b5b4f;
  margin-bottom: 1.5rem;
`;

const Subheading = styled.p`
  font-size: 1.25rem;
  color: #4a3728;
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  justify-content: center;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: "Spectral", serif;
`;
const PrimaryButton = styled(Button)`
  background-color: ${(props) =>
    props.disabled ? "#d3d3d3" : "#8b4513"}; /* Gray out if disabled */
  color: ${(props) =>
    props.disabled
      ? "#a9a9a9"
      : "#f0e6d2"}; /* Text color changes when disabled */
  border: none;
  box-shadow: ${(props) =>
    props.disabled
      ? "none"
      : "0 4px 6px rgba(139, 69, 19, 0.2)"}; /* Remove box shadow when disabled */
  cursor: ${(props) =>
    props.disabled ? "not-allowed" : "pointer"}; /* Disable the cursor */

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "#d3d3d3"
        : "#a0522d"}; /* No hover effect when disabled */
    transform: ${(props) => (props.disabled ? "none" : "translateY(-2px)")};
    box-shadow: ${(props) =>
      props.disabled
        ? "none"
        : "0 6px 8px rgba(139, 69, 19, 0.3)"}; /* No hover shadow if disabled */
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: #8b4513;
  border: 2px solid #8b4513;

  &:hover {
    background-color: rgba(139, 69, 19, 0.1);
    transform: translateY(-2px);
  }
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  color: #4a3728;
`;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
  background-color: #e6d8c3;
  clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
`;

const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FeatureCard = styled.div`
  background-color: #f8f3e9;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease;
  min-width: 300px;

  &:hover {
    transform: translateY(-10px) rotate(2deg);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #8b4513;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #4a3728;
`;

const FeatureDescription = styled.p`
  color: #6b5b4f;
  line-height: 1.6;
`;

const CTASection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #d2b48c;
  border-radius: 2rem;
  padding: 4rem 2rem;
  margin: 5rem auto;
  max-width: 1000px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    transform: rotate(45deg);
    animation: ${shimmer} 3s infinite;
  }
`;

const CTAButton = styled(PrimaryButton)`
  font-size: 1.25rem;
  padding: 1rem 2rem;
  margin-top: 2rem;
  z-index: 1;
`;

const Footer = styled.footer`
  background-color: #4a3728;
  color: #f0e6d2;
  padding: 3rem 1rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled.div`
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #f0e6d2;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #d2b48c;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #f0e6d2;
    }
  }
`;

const Copyright = styled.div`
  max-width: 1200px;
  margin: 2rem auto 0;
  padding-top: 1rem;
  border-top: 1px solid #6b5b4f;
  text-align: center;
  color: #d2b48c;
  font-size: 0.875rem;
`;

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0e6d2;
  font-size: 2rem;
  color: #8b4513;

  &:after {
    content: "...";
    animation: ellipsis 1.5s infinite;
  }

  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;
