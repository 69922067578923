import React from "react";
import styled from "@emotion/styled";

import { Pricing } from "./Pricing";

export const PricingPage = () => {
  return (
    <PageWrapper>
      <Pricing />
    </PageWrapper>
  );
};

// Styled Components
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  overflow-y: auto; /* Enable vertical scrolling */
`;
