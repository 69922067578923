import React from "react";
import {
  ArrowRight,
  BookOpen,
  Brain,
  Search,
  Star,
  Users,
  Mic,
} from "lucide-react";
import styled from "@emotion/styled";
import { css, Global, keyframes } from "@emotion/react";
import Book from "../images/books.jpg";
import { books } from "../static/books";
import { useNavigate } from "react-router-dom";
import {
  BookAuthor,
  BookCard,
  BookCover,
  BookGrid,
  BookGridContainer,
  BookInfo,
  BookRating,
  BookTitle,
  Copyright,
  Footer,
  FooterContent,
  FooterSection,
} from "../styles/styledComponents";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";

export const LandingPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  const startChatFromKit = (input) => {
    console.log(`Starting chat with: ${input}`);
    // Implement navigation logic here
  };

  const goToLibrary = () => {
    console.log("Navigating to /library");
    navigate("/library");
  };

  const goHome = () => {
    console.log("Navigating to /");
    navigate("/");
  };

  const goToBook = (id) => {
    console.log(`Navigating to /library/${id}`);
    navigate(`/library/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>A voice-based learning app</title>
        <meta name="description" content={`A voice-based learning app`} />
        <link rel="canonical" href={`https://www.speakwithbooks.com/`} />
      </Helmet>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <HeroSection>
            <HeroContent>
              <Heading>
                Non-fiction Audiobooks with AI for {""}
                <GradientText>powerful learning</GradientText>
              </Heading>
              <Subheading>
                Immerse yourself in the world of books like never before.
                Converse, explore, and unravel literary wonders with our AI
                companion.
              </Subheading>
              <ButtonGroup>
                <PrimaryButton onClick={goToLibrary}>
                  Purchase a book <ArrowRight size={16} />
                </PrimaryButton>
                <SecondaryButton onClick={goToLibrary}>
                  Explore Our Library
                </SecondaryButton>
              </ButtonGroup>
            </HeroContent>
            <HeroImageWrapper onClick={goToLibrary}>
              <HeroImage src={Book} alt="AI-powered book conversations" />
            </HeroImageWrapper>
          </HeroSection>

          <BooksSection id="books">
            <SectionTitle>Explore Our Literary Universe</SectionTitle>
            <BookGridContainer>
              <BookGrid>
                {books.map((book) => (
                  <BookCard key={book.id} onClick={() => goToBook(book.id)}>
                    <div style={{ position: "relative" }}>
                      {book.forKids && (
                        <>
                          <KidsBadge>For Kids!</KidsBadge>
                          <FloatingBubble
                            color="#FFD700"
                            style={{ top: "-10px", right: "30px" }}
                          />
                          <FloatingBubble
                            color="#FF69B4"
                            style={{ top: "-15px", right: "50px" }}
                            delay="0.5s"
                          />
                        </>
                      )}
                      {book.forKids ? (
                        <KidsBookCover
                          src={book.cover}
                          alt={`Cover of ${book.title}`}
                        />
                      ) : (
                        <BookCover
                          src={book.cover}
                          alt={`Cover of ${book.title}`}
                        />
                      )}
                    </div>
                    <BookInfo>
                      <BookTitle>{book.title}</BookTitle>
                      <BookAuthor>by {book.author}</BookAuthor>
                      <BookRating>
                        <Star size={16} color="#FFD700" fill="#FFD700" />
                        {/* {book.rating.toFixed(1)} */}
                        Not yet reviewed
                      </BookRating>
                      <ExploreButton>
                        Try it now <ArrowRight size={16} />
                      </ExploreButton>
                    </BookInfo>
                  </BookCard>
                ))}
              </BookGrid>
            </BookGridContainer>
            <ExploreMoreWrapper>
              <ExploreMoreButton onClick={goToLibrary}>
                Discover Our Full Collection
              </ExploreMoreButton>
            </ExploreMoreWrapper>
          </BooksSection>

          <FeaturesSection id="features">
            <SectionTitle>Why Choose SpeakWithBooks?</SectionTitle>
            <FeatureGrid>
              {[
                {
                  icon: <Mic size={48} />,
                  title: "Voice Interaction",
                  description:
                    "Engage in natural conversations with your favorite books",
                },
                {
                  icon: <Brain size={48} />,
                  title: "AI-Powered Insights",
                  description:
                    "Gain deep understanding with intelligent analysis",
                },
                {
                  icon: <BookOpen size={48} />,
                  title: "Vast Library",
                  description:
                    "Access a world of knowledge across countless AI Audiobooks",
                },
                {
                  icon: <Star size={48} />,
                  title: "Personalized Recommendations",
                  description: "Discover new books tailored to your interests",
                },
              ].map((feature, index) => (
                <FeatureCard key={index}>
                  <FeatureIcon>{feature.icon}</FeatureIcon>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </FeatureCard>
              ))}
            </FeatureGrid>
          </FeaturesSection>

          <CTASection>
            <SectionTitle>
              Ready to Embark on Your Literary Journey?
            </SectionTitle>
            <Subheading>
              Join a community of book lovers and AI enthusiasts. Unlock the
              power of intelligent literary conversations today!
            </Subheading>
            <CTAButton onClick={goToLibrary}>
              Begin Your Adventure <ArrowRight size={20} />
            </CTAButton>
          </CTASection>
        </div>

        <Footer>
          <FooterContent>
            <FooterSection>
              <h3>SpeakWithBooks</h3>
              <p>Explore a world of AI audiobooks</p>
            </FooterSection>
            <FooterSection>
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#books">Library</a>
                </li>
                <li>
                  <a href="#features">Features</a>
                </li>
                {isAuthenticated ? (
                  <li>
                    <a href="#" onClick={() => logout()}>
                      Logout
                    </a>
                  </li>
                ) : (
                  <li>
                    <a href="#" onClick={() => loginWithRedirect()}>
                      Login
                    </a>
                  </li>
                )}
              </ul>
            </FooterSection>
          </FooterContent>
          <Copyright>
            <p>
              &copy; {new Date().getFullYear()} SpeakWithBooks. All rights
              reserved.
            </p>
          </Copyright>
        </Footer>
      </Container>
    </>
  );
};

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f8f3e9;
  overflow-y: auto;
  overflow-x: hidden;

  margin: 0;
`;

const HeroSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 1rem;
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HeroContent = styled.div`
  flex: 1;
`;

const HeroImageWrapper = styled.div`
  flex: 1;
  position: relative;
  height: 400px;
  cursor: pointer;
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, #5e3023, #8b4513);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
  -webkit-background-clip: text;
  color: transparent;
`;

const Heading = styled.h1`
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subheading = styled.p`
  font-size: 1.25rem;
  color: #5e3023;
  max-width: 600px;
  margin-bottom: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PrimaryButton = styled(Button)`
  background-color: #5e3023;
  color: #f8f3e9;
  border: none;

  &:hover {
    background-color: #8b4513;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: #5e3023;
  border: 2px solid #5e3023;

  &:hover {
    background-color: #e6d8c3;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  color: #5e3023;
`;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
  background-color: #fff;
  width: 100%;
`;

const FeatureGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 2rem;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FeatureCard = styled.div`
  background-color: #f8f3e9;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
  min-height: 225px;
  max-width: 225px;

  &:hover {
    transform: translateY(-5px);
  }
  @media (max-width: 768px) {
    max-width: 250px;
    min-height: 200px;
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #5e3023;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #5e3023;
`;

const FeatureDescription = styled.p`
  color: #3a2f2d;
`;

const BooksSection = styled.section`
  padding: 5rem 1rem;
  background-color: #f8f3e9;
  width: 100%;
`;

const ExploreButton = styled.button`
  background-color: #5e3023;
  color: #f8f3e9;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #8b4513;
    transform: translateX(5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`;

const StartLearning = styled.div`
  display: flex;
  align-items: center;
  color: #5e3023;
  font-weight: 600;
`;

const ExploreMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
  width: 100%;
`;

const ExploreMoreButton = styled(Button)`
  background-color: #5e3023;
  color: #f8f3e9;
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1.1rem;

  &:hover {
    background-color: #8b4513;
  }
`;

const CTASection = styled.section`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e6d8c3;
  border-radius: 1rem;
  padding: 4rem 2rem;
  margin: 5rem 0;
  max-width: 1200px;
`;

const CTAButton = styled(PrimaryButton)`
  font-size: 1.25rem;
  padding: 1rem 2rem;
  margin-top: 2rem;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

  a {
    color: #f8f3e9;
    transition: color 0.3s;

    &:hover {
      color: #e6d8c3;
    }
  }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const KidsBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(45deg, #8a2be2, #ff69b4);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  transform: rotate(6deg);
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const FloatingBubble = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  opacity: 0.7;
  animation: ${floatAnimation} 2s ease-in-out infinite;
  animation-delay: ${(props) => props.delay || "0s"};
`;

const KidsBookCover = styled(BookCover)`
  border: 3px dashed #9c27b0;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    border-color: #ff69b4;
  }
`;
