import React from "react";
import { Sparkles } from "lucide-react";

const KidsBookUI = () => {
  return (
    <div className="relative" style={{ margin: 10 }}>
      {/* Fun floating bubbles background */}
      <div className="absolute -top-4 right-4 animate-bounce">
        <div className="w-8 h-8 rounded-full bg-yellow-300 opacity-70"></div>
      </div>
      <div className="absolute -top-8 right-12 animate-bounce delay-150">
        <div className="w-6 h-6 rounded-full bg-pink-300 opacity-70"></div>
      </div>
      <div className="absolute -top-6 right-20 animate-bounce delay-300">
        <div className="w-5 h-5 rounded-full bg-blue-300 opacity-70"></div>
      </div>

      {/* Kids' Book Badge */}
      <div className="absolute -top-6 -left-6 transform rotate-[-12deg]">
        <div className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 py-2 rounded-full shadow-lg flex items-center gap-2">
          <Sparkles className="w-5 h-5" />
          <span className="font-bold">Kids' Book!</span>
        </div>
      </div>
    </div>
  );
};

export default KidsBookUI;
