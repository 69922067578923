import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain="speakwithbooks.us.auth0.com"
      clientId="vh9acnoFNssBbqereZCftpk1kFMnNpS3"
      authorizationParams={{
        audience: "https://www.speakwithbooks.com",
        redirectUri: window.location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);

reportWebVitals();
