import { runLocal, testBook } from "./debug";

const baseUrl = runLocal
  ? "http://127.0.0.1:5000/"
  : "https://main-backend-api-332ea47a673b.herokuapp.com/";

export const streamAudio = async (token, sessionId, id, base64Audio) => {
  const formData = new FormData();
  formData.append("bookId", id);
  formData.append("testBook", testBook);
  if (base64Audio) formData.append("userAudio", base64Audio); // Send the base64 audio data
  if (sessionId) formData.append("sessionId", sessionId);

  const response = await fetch(`${baseUrl}/api/book/stream_audio`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getNextMessageStreaming = async (
  token,
  userMessage,
  sessionId,
  abTests
) => {
  const url = `${baseUrl}/chat`;

  // Create a FormData object and append the data
  const formData = new FormData();
  formData.append("userMessage", JSON.stringify(userMessage));
  if (sessionId) formData.append("sessionId", sessionId);
  if (abTests) formData.append("abTests", JSON.stringify(abTests));

  const response = fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  return response;
};

export const getNextMessageStreamingUnauthenticated = async (messages) => {
  const url = `${baseUrl}/chat_unauthenticated`;

  // Create a FormData object and append the data
  const formData = new FormData();
  if (messages) formData.append("messages", JSON.stringify(messages));

  return fetch(url, {
    method: "POST",
    body: formData,
  });
};

export const getSessions = async (token, abTests) => {
  const url = `${baseUrl}/get_sessions`;
  const formData = new FormData();
  if (abTests) formData.append("abTests", JSON.stringify(abTests));

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });

  return response;
};

export const getMessagesFromSession = async (token, sessionId) => {
  const url = `${baseUrl}/get_messages_from_session`;

  // Create a FormData object and append the sessionId
  const formData = new FormData();
  formData.append("sessionId", sessionId);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });

  return response;
};

export const assignSessionTitle = async (token, sessionId) => {
  const url = `${baseUrl}/assign_session_title`;
  const formData = new FormData();
  formData.append("sessionId", sessionId);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });

  return response;
};

export const createCheckOutSesssion = async (token) => {
  try {
    const url = `${baseUrl}api/book/create_checkout_session`;
    const formData = new FormData();
    formData.append("runLocal", runLocal);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const session = await response.json();
    console.log("session", session);

    window.location.href = session.url;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const saveTodo = async (token, todo) => {
  try {
    const url = `${baseUrl}/save_todo`;
    const formData = new FormData();
    formData.append("todo", JSON.stringify(todo));

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();
    // console.log("result", result);

    return result;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const editTodo = async (token, todoId, updatedTodo) => {
  try {
    const url = `${baseUrl}/edit_todo`;
    const formData = new FormData();
    formData.append("todoId", todoId);
    formData.append("updatedTodo", JSON.stringify(updatedTodo));

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();
    // console.log("result", result);
    //
    return result;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const deleteTodo = async (token, todoId) => {
  try {
    const url = `${baseUrl}/delete_todo`;
    const formData = new FormData();
    formData.append("todoId", todoId);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();
    // console.log("result", result);

    return result;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getTodos = async (token, abTests) => {
  try {
    const url = `${baseUrl}/get_todos`;

    const formData = new FormData();
    if (abTests) formData.append("abTests", JSON.stringify(abTests));

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();
    // console.log("result", result);

    return result;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const cancelSubscription = async (token) => {
  try {
    const url = `${baseUrl}/cancel_subscription`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    // console.log("result", result);

    return result;
  } catch (error) {
    console.error("Error:", error);
  }
};

// if (data.error && data.rate_limit) {
//   setIsRateLimited(true);
// } else {
//   const aiMessage = { text: data.response, sender: "ai" };
//   const updatedMessages = [...newMessages, aiMessage];
//   // updateMessages(character.id, updatedMessages);
//   if (data.request_count && data.request_count) {
//     // setRequestCount(data.request_count);
//     // setRequestLimit(data.request_limit);
//   }
// }
