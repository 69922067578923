import { createContext, useContext, useState } from "react";

// Create the context
const GeneralContext = createContext();

// Create a provider component
export const GeneralProvider = ({ children }) => {
  // Example function to change state

  return (
    <GeneralContext.Provider value={{}}>{children}</GeneralContext.Provider>
  );
};

// Custom hook for using this context
export const useGeneral = () => useContext(GeneralContext);
